(function($, window) {
    window.Users = {
        memberName: 'Customer',
        coachName: 'Coach',
        coachRoleId: '2',
        memberRoleId: '3',
        statuses: {
            activeName: 'Active',
            notActiveName: 'Not Active',
            confirmedName: 'Confirmed'
        },
        init: function() {
            $(window.document)
                .on('click', '#btnShowUsersModal', this.showCreateUserModal)
                .on('click', '.btnCreateUser', this.create)
                .on('click', '[data-module="users"] .btnFilter', this.filter)
                .on('change', 'select[name="role_id"]', this.updMarketSelect)
                .on('change', 'select[name="role_id"]', this.updateStatusSelect)
                .on('show.bs.collapse', '#listUsers .collapse', function(e) {
                    var contextElementId = '#' + e.currentTarget.id + ' select[name="role_id"]';
                    window.Users.updateStatusSelect(contextElementId);
                })
                .on('keypress', 'input[name="namefilter"]', function(e) {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $('.btnFilter').click();
                        return false;
                    }
                }).on('focusin', 'tr, button, select', function(e) {
                    e.stopImmediatePropagation();
                });

            window.General.initChosenWithSearch($('select.cbCountry'));
            window.General.initChosen($('select.cbUsersChosen'));
            window.General.initChosen($('select.cbUserMarkets, select.cbUserMarketsMember'));
            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());

            $('select.cbFilter[name="direction"]').val('DESC');
            $('select.cbFilter[name="direction"]').trigger('chosen:updated');
        },
        showCreateUserModal: function() {
            window.General.clearCreateFields();
            window.General.initChosen($('.modal.user-create select.cbUserMarkets'));
            $('.modal.user-create').modal('show');
        },
        create: function() {
            window.General.save($(this), window.Users.applyFilter);
        },
        updMarketSelect: function() {
            var $form = $(this).parents('form'),
                $currRole = $(this).find('option:selected');
            window.Users.setAsterisks($form, $currRole);
        },
        setAsterisks: function($form, $currRole) {
            var $boxMarkets = $form.find('.sbUserMarkets'),
                $selectMarkets = $boxMarkets.find('.cbUserMarkets'),
                $selectMarketsMember = $boxMarkets.find('.cbUserMarketsMember'),
                $selectedOption = $currRole;

            $form.find('input').parents('.form-group').removeClass('star-sign');
            $boxMarkets.find('.required_field_member').addClass('hide');
            if ($selectedOption.val() === window.Users.memberRoleId) {
                $selectMarkets.addClass('hide');
                $selectMarketsMember.removeClass('hide');
                $selectMarketsMember.trigger('chosen:updated');

                $form.find('.cbCountry').parents('.form-group').addClass('star-sign');
                $boxMarkets.removeClass('hide');
            } else if ($selectedOption.val() === window.Users.coachRoleId) {
                $selectMarketsMember.addClass('hide');
                $selectMarkets.removeClass('hide');
                $selectMarkets.trigger('chosen:updated');

                $boxMarkets.removeClass('hide');
            } else {
                $boxMarkets.addClass('hide');
            }
        },
        updateStatusSelect: function(context) {
            var $context,
                $form,
                $selectedOption,
                $selectStatuses,
                $confirmedStatusOption;

            if (typeof context === 'string' || context instanceof String) {
                $context = $(context);
            } else {
                $context = $(this);
            }

            $form = $context.parents('form');
            $selectedOption = $context.find('option:selected');
            $selectStatuses = $form.find('select[name="status_id"]');
            $confirmedStatusOption = $selectStatuses.find(
                'option:contains("' + window.Users.statuses.confirmedName + '")'
            );

            if ($selectedOption.text() === window.Users.memberName) {
                $confirmedStatusOption.removeAttr('disabled');
                $selectStatuses.trigger('chosen:updated');
            } else if ($selectedOption.text() === window.Users.coachName) {
                $confirmedStatusOption.attr('disabled', 'disabled');
                $selectStatuses.trigger('chosen:updated');
            } else {
                $confirmedStatusOption.attr('disabled', 'disabled');
                $selectStatuses.trigger('chosen:updated');
            }
        },
        filter: function() {
            window.General.filterParams = $('form#formFilters').serializeArray();
            window.General.doFilter(window.General.filterParams);
        },
        applyFilter: function() {
            $('.modal').modal('hide');
            window.General.doFilter(window.General.filterParams);
        }
    };

    // DOM ready event
    $(function usersInit() {
        window.Users.init();
    });
}(window.$, window));
