(function($, window) {
    window.Payments = {
        init: function() {
            $(window.document)
                .on('click', '[data-module^="payments"] .btnFilter', this.filter)
                .on('click', '.userInfo', this.getUserInfo);

            window.Payments.setDefaultMarket();
            window.Payments.initPaymentInterval();
            window.Payments.initCharts();
        },
        filter: function() {
            $('input[name="marketId"]').val($('#ddMarketlist').val());
            $('input[name="startDate"]').val($('#startDate').val());
            $('input[name="endDate"]').val($('#endDate').val());
            window.General.filterParams = $('form#formFilters').serializeArray();
            window.General.doFilter(window.General.filterParams);
        },
        getUserInfo: function() {
            var $tr = $(this),
                $modal = $('#userInfoModal');

            $.ajax({
                url: '/payments/get-user',
                type: 'GET',
                data: { 'userId': $tr.data('id') },
                success: function(responce) {
                    $modal.find('.modal-body').html(responce);
                    $modal.modal('show');
                }
            });
        },
        getNewGraphSection: function($params) {
            $.ajax({
                url: '/payments/get-chart',
                type: 'GET',
                data: $params,
                success: function(responce) {
                    $('.stats').html(responce);
                    $('.convert-comma').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });
                    window.Payments.initCharts();
                }
            });
        },
        initPaymentInterval: function() {
            var nowTemp = new Date(),
                now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0),
                checkin, checkout;

            checkin = $('#startDate').datepicker({
                format: 'yyyy-mm-dd',
                onRender: function(date) {
                    return date.valueOf() < now.valueOf() ? 'disabled' : '';
                }
            }).on('changeDate', function(ev) {
                var newDate = new Date(ev.date);
                newDate.setDate(newDate.getDate() + 1);
                checkout.setStartDate(newDate);
                checkin.hide();

                if ($('#startDate').val() !== $('input[name="startDate"]').val()) {
                    $('input[name="startDate"]').val($('#startDate').val());
                    window.General.filterParams = $('form#formFilters').serializeArray();
                    window.General.doFilter(window.General.filterParams);
                }
            }).data('datepicker');

            checkout = $('#endDate').datepicker({
                format: 'yyyy-mm-dd',
                onRender: function(date) {
                    return date.valueOf() <= checkin.viewDate.valueOf() ? 'disabled' : '';
                }
            }).on('changeDate', function(ev) {
                var newDate = new Date(ev.date);

                checkin.setEndDate(newDate);
                checkout.hide();

                if ($('#endDate').val() !== $('input[name="endDate"]').val()) {
                    $('input[name="endDate"]').val($('#endDate').val());
                    window.General.filterParams = $('form#formFilters').serializeArray();
                    window.General.doFilter(window.General.filterParams);
                }
            }).data('datepicker');
        },
        initCharts: function() {
            var $chartPayments = $('#chartPayments'),
                lineChartData,
                context;

            if ($chartPayments && $chartPayments.length === 0) {
                return;
            }

            lineChartData = {
                labels: prepareChartLabels($chartPayments),
                datasets: [
                    {
                        label: 'Payments',
                        fillColor: 'rgba(151,187,205,0.2)',
                        strokeColor: 'rgba(151,187,205,1)',
                        pointColor: 'rgba(151,187,205,1)',
                        pointStrokeColor: '#fff',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(151,187,205,1)',
                        data: prepareChartData($chartPayments)
                    }
                ]

            };
            context = $chartPayments.get(0).getContext('2d');

            new window.Chart(context).Line(lineChartData, {
                pointHitDetectionRadius: 1
            });
        },
        setDefaultMarket: function() {
            var idMarket = $('[name="marketId"]').val();

            $('#ddMarketlist [value="' + idMarket + '"]').attr('selected', 'selected');
            $('#ddMarketlist').trigger('chosen:updated');
        }
    };

    function prepareChartData($element) {
        var chartDataSource,
            chartDataSourceString,
            chartData = [];

        chartDataSource = $element.data('arg');
        if (chartDataSource) {
            chartDataSourceString = (chartDataSource).toString(10);
            if (chartDataSourceString.indexOf(',') !== false) {
                chartData = chartDataSourceString.split(',');
            } else {
                chartData = [chartDataSource];
            }
        }
        return chartData;
    }

    function prepareChartLabels($element) {
        var labelDataSource,
            labelData = [];

        labelDataSource = $element.data('label');
        if (labelDataSource) {
            if (labelDataSource.indexOf(',') !== false) {
                labelData = labelDataSource.split(',');
            } else {
                labelData = [labelDataSource];
            }
        }
        return labelData;
    }

    // DOM ready event
    $(function paymentsInit() {
        window.Payments.init();
    });
}(window.$, window));
