(function($, window) {
    $.fn.modal.Constructor.DEFAULTS.backdrop = 'static';
    $.ajaxPrefilter(function(options) {
        if (typeof (options.data) === 'string') {
            options.data = options.data
                .split('&')
                .map(function(paramLine) {
                    var lineItems = paramLine.split('=');
                    if (!lineItems || lineItems.length === 1) {
                        return paramLine;
                    }
                    return lineItems[0] + '=' + window.General.convertDecimalValue(decodeURIComponent(lineItems[1]));
                })
                .join('&');
        }
    });
    window.General = {
        filterParams: $('#formFilters').serializeArray(),
        init: function() {
            $(window.document).on('click', '.btnEdit', this.edit)
                .on('click', '.btnDelete', this.deleteItem)
                .on('click', '.btnCancel', this.cancel)
                .on('click', '.btnCancelModal', this.cancelModal)
                .on('click', '.btnDeleteModal', this.deleteModal)
                .on('change', 'input, select, textarea', this.markTrAsChanged)
                .on('click', 'tr[data-toggle="collapse"]', this.toggleCollapse)
                .on('click', '.clearFilters', this.clearFilters)
                .on('keydown', 'ol.steps-empty[contenteditable]', this.checkEmptySteps)
                // .on('keyup', 'ol.steps-empty[contenteditable]', this.checkEmpfiltertyStepsFF) // TODO ???
                .on('shown.bs.collapse', '.items tr.collapse', this.moveUnCollapsedInToView)
                .on('click', '.navbar-nav li', this.clickMenuItem);

            this.clearCreateFields();
        },
        edit: function() {
            var $btnSave = $(this),
                $tr = $(this).parents('tr');

            window.General.save($btnSave, window.General.collapseHide.bind(window.General, $tr));
        },
        create: function() {
            var $btnCreate = $(this),
                $modal = $('.modal');

            window.General.save($btnCreate, window.General.closeModal.bind(window.General, $modal));
        },
        save: function($btn, next) {
            var $form = $btn.parents('form'),
                $errors = $form.prev(),
                idParent = $btn.parents('tr').attr('id'),
                $trList = $('[data-target = "#' + idParent + '"]'),
                currentLanguage = $('.listItems').find('#currLang'),
                formParamNames = ['name'];

            // $btn.attr('disabled', 'disabled');

            if (currentLanguage.length === 1) {
                $form.find('[name="languages_id"]').val(currentLanguage.val());
                $form.find('[name="language_id"]').val(currentLanguage.val());
            }

            $errors.addClass('hide');
            $.ajax({
                url: $form.attr('action'),
                data: getDataWithEncodedParams($form, formParamNames),
                type: $form.attr('method'),
                error: function(xhr) {
                    window.General.convertErrorsToList($errors, xhr.responseText);
                    // $btn.removeAttr('disabled');
                },
                success: function(response) {
                    var $td = $form.parents('td'),
                        $navUser = $('#nav_user_name'),
                        formUserId = $form.find('input[name="id"]').val(),
                        flChangeNavBar = ($('#formFilters[data-module="users"]').length === 1
                            && $navUser.data('id') === parseInt(formUserId, 10)) ? 1 : 0,
                        newUserName = $form.find('[name="firstname"]').val()
                            + ' '
                            + $form.find('[name="lastname"]').val(),
                        msgClass;
                    $td.html(response);

                    window.General.updateTargetFields($trList, $td.find('form'));
                    if (flChangeNavBar === 1) {
                        $navUser.html(newUserName);
                    }

                    if ($('[data-module="exercises/market/{marketId}"], [data-module="marketId"]').length > 0
                        || $('[data-module="exercises/market/{marketId}"], [data-module="exercises"]').length > 0
                    ) {
                        window.Exercises.getTreeData($('input[name="categoryId"]').val());
                    }

                    msgClass = $btn.hasClass('btnCreate') ? $('.alertSavedSuccess') : $('.alertEditedSuccess');
                    window.General.displayMsg(msgClass);
                    // $btn.removeAttr('disabled');
                    next();
                }
            });
        },
        deleteItem: function() {
            var $btn = $(this),
                $errors = $btn.parents('form').prev(),
                $counterRows = $('#counterRows');

            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $.ajax({
                    url: $btn.data('action'),
                    data: {'_token': $btn.data('token')},
                    type: 'DELETE',
                    success: function(responce) {
                        var $tr,
                            idEditForm;

                        if (responce.status) {
                            $tr = $btn.parents('tr');
                            idEditForm = $tr.attr('id');

                            if ($tr.length > 0) {
                                $('[data-target="#' + idEditForm + '"]').remove();
                                $tr.remove();
                            } else {
                                $('tr[data-content-id="' + $btn.data('id') + '"]').remove();
                            }

                            $counterRows.html(parseInt($counterRows.html(), 10) - 1);
                            $('.modal').modal('hide');
                            window.General.displayMsg($('.alertSuccessDelete'));
                        }
                    },
                    error: function(xhr) {
                        $('.modal').modal('hide');
                        window.General.convertErrorsToList($errors, xhr.responseText);
                    }
                });
            });

            return false;
        },
        deleteModal: function() {
            var $btn = $(this),
                $errors = $btn.parents('form').prev(),
                $counterRows = $('#counterRows');

            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $.ajax({
                    url: $btn.data('action'),
                    data: {'_token': $btn.data('token')},
                    type: 'DELETE',
                    success: function(responce) {
                        var id,
                            $tr,
                            idEditForm;

                        if (responce.status) {
                            id = $btn.attr('data-id');

                            if ($btn.attr('data-delete-type') === 'msg-rm-exercise-category') {
                                window.Exercises.getTreeData($btn.attr('data-parent-id'));
                            } else {
                                $tr = $('tr[data-content-id="' + id + '"]');
                                idEditForm = $($tr.attr('data-recipe-target'));

                                $tr.remove(); // remove the source for the edit form
                                $(idEditForm).remove();
                                $counterRows.html(parseInt($counterRows.html(), 10) - 1);
                            }

                            $('.modal').modal('hide');
                            window.General.displayMsg($('.alertSuccessDelete'));
                        }
                    },
                    error: function(xhr) {
                        $('.btnConfirmAction').parents('.modal').modal('hide');
                        window.General.convertErrorsToList($errors, xhr.responseText);
                    }
                });
            });

            return false;
        },

        saveModal: function() {
            var $btn = $(this),
                $form = $btn.parents('form'),
                $errors = $form.prev(),
                currentLanguage = $('.listItems').find('#currLang'),
                formParamNames = ['name'];

            if ($btn.hasClass('flDataChanged')) {
                return;
            }
            $btn.addClass('flDataChanged');

            if (currentLanguage.length === 1) {
                $form.find('[name="languages_id"]').val(currentLanguage.val());
            }

            window.General.updStepsTextarea($form);
            $errors.addClass('hide');

            $.ajax({
                url: $form.attr('action'),
                data: getDataWithEncodedParams($form, formParamNames),
                type: $form.attr('method'),
                error: function(xhr) {
                    $btn.removeClass('flDataChanged');
                    window.General.convertErrorsToList($errors, xhr.responseText);
                },
                success: function(response) {
                    $btn.removeClass('flDataChanged');
                    if (response.status === true) {
                        $('.modal').modal('hide');
                        $('.modal-backdrop').remove();
                        window.General.displayMsg($('.alertEditedSuccess'));
                        window.General.doFilter(window.General.filterParams);
                    }
                }
            });
        },
        showConfirmModal: function($btn) {
            var msgDelete = $btn.attr('data-delete-type'),
                $confirmModal = $('#confirmModal'),
                $msg = $confirmModal.find('.modal-body p:first');

            if (typeof msgDelete !== 'undefined') {
                $msg = $confirmModal.find('[data-delete-type="' + msgDelete + '"]');
            }

            $msg.removeClass('hide').siblings().addClass('hide');

            $confirmModal.modal('show');
        },
        convertErrorsToList: function($errors, errors) {
            var $ul = $errors.find('ul');
            $ul.empty();

            $.each($.parseJSON(errors), function(index, item) {
                $ul.append(
                    $(window.document.createElement('li')).text(item)
                );
            });

            $('.btnImgAction').removeClass('hide');
            $('.btn').button('reset');
            $errors.removeClass('hide');
        },
        updateTargetFields: function($trList, $form) {
            $.each($trList.find('[data-target-field]'), function(key, td) {
                var id = $(td).data('target-field'),
                    sourceValue = '',
                    items;

                switch ($(td).data('target-type')) {
                    case 'input':
                        items = id.split(',');
                        $.each(items, function(itemsKey, item) {
                            sourceValue += $form.find('[name="' + item + '"]').val() + ' ';
                        });
                        break;
                    case 'select':
                        sourceValue = $form.find('[name="' + id + '"] option:selected').text();
                        break;
                    default :
                        break;
                }

                $(td).html(sourceValue);
            });
        },
        collapseHide: function($tr) {
            $tr.collapse('hide');
            window.General.initChosenWithSearch($('select.cbCountry'));
            window.General.initChosen($('select.cbUsersChosen'));
            window.General.initChosen($('select.cbUserMarkets, select.cbUserMarketsMember'));
        },
        closeModal: function($modal) {
            $modal.modal('hide');
            window.General.displayMsg($('.alertSuccess'));
            window.General.doFilter(window.General.filterParams);
        },
        displayMsg: function($msg) {
            $msg.removeClass('hide').addClass('in');
            setTimeout(function() {
                $msg.removeClass('in').addClass('hide');
            }, 1000);
        },
        markTrAsChanged: function() {
            $(this).parents('form').find('.btnCancel, .btnCancelModal').addClass('flDataChanged');
        },
        cancel: function(e) {
            var $btn = $(this);

            if (!$btn.hasClass('flDataChanged')) {
                $btn.parents('tr').collapse('hide');
                $('.modal').modal('hide');
                return;
            }

            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $.ajax({
                    url: $btn.data('action'),
                    type: 'GET',
                    success: function(response) {
                        var chosenInitSelector = '#listUsers select.cbUserMarkets, '
                            + '#listUsers select.cbUserMarketsMember, '
                            + '#listUsers select.cbUsersChosen';

                        $btn.parents('tr').collapse('hide');
                        $btn.parents('td').html(response);

                        window.General.initChosen($(chosenInitSelector));
                        window.General.initChosenWithSearch($('select.cbCountry'));
                        $('.modal').modal('hide');
                    }
                });
            });
            e.stopImmediatePropagation();
        },
        cancelModal: function() {
            var $btn = $(this);

            if (!$btn.hasClass('flDataChanged')) {
                $('.modal').modal('hide');
                return;
            }

            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $('.modal').modal('hide');
            });
        },
        toggleCollapse: function(e) {
            var id = $(this).data('target'),
                $form,
                $currRole;

            if ($(id).hasClass('in')) {
                e.stopPropagation();
                $(id).find('.btnCancel').trigger('click');
            } else {
                $form = $(id);
                $currRole = $form.find('[name="role_id"] option:selected');

                if ($('#formFilters[data-module="users"]').length === 1) {
                    window.Users.setAsterisks($form, $currRole);
                }
            }
        },
        clearCreateFields: function() {
            var $form = $('form[data-type-form="create"]');

            $form.find('.imgRecipe').attr('src', '');
            $form.find('input[type="text"], textarea').val('');
            $form.find('select option').removeAttr('selected');
            $form.find('.btnDeleteImg').addClass('hide');
            $form.find('.btnUploadImg').removeClass('hide');
            $form.prev('.alert').addClass('hide');
            $form.find('.sbUserMarkets').addClass('hide');
            $form.find('.chosen-select').val('').trigger('chosen:updated');
            $form.find('input').parents('.form-group').removeClass('star-sign');

            $('.btn').button('reset');
        },
        clickMenuItem: function(e) {
            if (window.localStorage.marketId && $(this).hasClass('with_market_dependence')) {
                e.preventDefault(false);

                window.location.href = $(this).find('a').attr('href') + '/market/' + window.localStorage.marketId;
            }
        },
        doFilter: function($params) {
            $('.btnFilter').attr('disabled', 'disabled');
            updFiltersToLatestAcceptedParams($params);

            $params.filter( function(obj) {
                if (obj.name === 'marketId') {
                    window.localStorage.marketId = obj.value;
                }
            });

            return $.ajax({
                url: $('#formFilters').attr('action'),
                data: $params,
                type: $('#formFilters input[name="_method"]').val(),

                success: function(responce) {
                    window.General.destroyInfiniteScroll(window.General.getInfiniteScrollSelector());

                    $('.listItems').html(responce);
                    $('tr[data-toggle="modal"]').unbind('click').on('click', window.Ingredients.translate);

                    if ($('#ddMarketlist').length === 0 || $('#ddMarketlist option').length > 0) {
                        $('.btnFilter').removeAttr('disabled');
                    }
                    if ($('[data-module="payments/market/{marketId}"], [data-module="payments"]').length > 0) {
                        window.Payments.getNewGraphSection($params);
                    }
                    $('.convert-comma').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });

                    window.General.initChosenWithSearch($('select.cbCountry'));
                    window.General.initChosen($('select.cbUsersChosen'));
                    window.General.initChosen(
                        $('#listUsers select.cbUserMarkets, #listUsers select.cbUserMarketsMember')
                    );

                    window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());
                },
                error: function() {
                    if ($('#ddMarketlist').length === 0 || $('#ddMarketlist option').length > 0) {
                        $('.btnFilter').removeAttr('disabled');
                    }
                }
            });
        },
        clearFilters: function() {
            var $form = $('#formFilters');

            $('input[name="marketId"]').val($('#ddMarketlist option:selected').val());
            $form.find('input[type="text"]').val('');
            $form.find('.chosen-select').val('').trigger('chosen:updated');
            window.General.filterParams = $form.serializeArray();
            window.General.doFilter(window.General.filterParams);
        },
        initFilters: function() {
            $('.cbFilter').chosen({
                disable_search_threshold: 1000,
                inherit_select_classes: true
            });
        },
        initChosen: function($el) {
            $el.chosen({disable_search_threshold: 1000, inherit_select_classes: true, display_selected_options: false});
        },
        initChosenWithSearch: function($el) {
            $el.chosen({inherit_select_classes: true});
        },
        checkEmptySteps: function(e) {
            var keycode = e.keyCode ? e.keyCode : e.which,
                firstLiHtml = $(this).find('li:first').html(),
                lastLiHtml = $(this).find('li:last').html(),
                ua,
                re;

            if ((keycode === 8 || keycode === 46)
                && $(this).find('li').length === 1
                && (firstLiHtml === '<br>' || firstLiHtml.html() === '')
            ) {
                e.stopImmediatePropagation();
                return false;
            }

            /* fix for IE steps */
            ua = navigator.userAgent;
            re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) !== null) {
                if (keycode === 13
                    && ( lastLiHtml === '' || lastLiHtml === '<br>' || typeof lastLiHtml === 'undefined')
                ) {
                    e.stopImmediatePropagation();
                    return false;
                } else if ((keycode === 8 || keycode === 46)) {
                    $('ol li').each(function() {
                        if ($(this).html() === '<br>'
                            || $(this).html() === ''
                            || typeof $(this).html() === 'undefined'
                        ) {
                            $(this).remove();
                            e.stopImmediatePropagation();
                            return false;
                        }
                    });
                }
            }
        },
        checkEmptyStepsFF: function() {
            if ($(this).find('li').length === 0) {
                $(this).html('<li></li>');
            }

            $('ol>br').remove();
        },
        updStepsTextarea: function($form) {
            var olTag;

            if ($form.find('ol.steps-empty[contenteditable]').length === 1) {
                olTag = $form.find('.steps-empty[contenteditable]').parent().html();

                $form.find('textarea[name="steps"]').html($.trim(olTag));
            }
        },
        getInfiniteScrollSelector: function() {
            return '#' + $('.listItems').children('table').first().attr('id');
        },
        initInfiniteScroll: function(containerSelector) {
            // Not really elegant but working way of using jquery-infinite-scroll in tables
            var loaderImage = $('<img alt="Loading..." src="/build/css/images/ajax-loader.gif" />'),
                loaderImageContainer = $('<div id="infscr-loading"></div>').append(loaderImage),
            // "colspan='100'" is kinda dirty hack for auto colspan - should be avoided in normal situations
                loaderImageCell = $('<td colspan="100"></td>').append(loaderImageContainer),
                loader = $('<tr class="center"></tr>').append(loaderImageCell),
                loadingOptions = {
                    msg: loader,
                    finished: function() {
                        var chosenInitSelector = 'select.cbUsersChosen, '
                            + '#listUsers select.cbUserMarkets, '
                            + '#listUsers select.cbUserMarketsMember';

                        window.General.initChosenWithSearch($('select.cbCountry'));
                        window.General.initChosen($(chosenInitSelector));

                        loader.fadeOut();
                    }
                },
                selector = containerSelector + ' .items';

            $(selector).infinitescroll({
                loading: loadingOptions,
                navSelector: containerSelector + ' .pagination',
                nextSelector: containerSelector + ' .pagination li.active + li a',
                itemSelector: containerSelector + ' .items .item',
                contentSelector: selector,
                path: function(index) {
                    var path = $(containerSelector + ' .pagination li.active + li a').attr('href');
                    if (path.indexOf('page=') > 0) {
                        return path + '&page=' + index;
                    }
                    return path + '?page=' + index;
                }
            });
        },
        destroyInfiniteScroll: function(containerSelector) {
            var selector = containerSelector + ' .items';
            $(selector).infinitescroll('destroy');
        },
        moveUnCollapsedInToView: function() {
            var selected = $(this),
                collapseHeight = $('.collapse.in').height(),
                bodyRect = window.document.body.getBoundingClientRect(),
                selectedRect = selected[0].getBoundingClientRect(),
                footerRect = $('footer.footer')[0].getBoundingClientRect(),
                selectedBottom = selectedRect.bottom - bodyRect.top,
                footerTop = footerRect.top - bodyRect.top,
                footerHeight = footerRect.bottom - footerRect.top,
                animationDuration = 500;

            if (selectedBottom > footerTop) {
                $.scrollTo(selected, animationDuration, {
                    offset: -(Math.abs($(window).height() - collapseHeight - footerHeight))
                });
            }
        },
        isEllipsisActive: function($e) {
            var span = $e.find('span');

            return (span.width() > $e.width());
        },

        editTranslationModal: function() {
            var $form = $(this).parents('form'),
                $errors = $form.prev();

            $errors.addClass('hide');
            window.General.updStepsTextarea($form);
            $.ajax({
                url: $form.attr('action'),
                data: $form.serializeArray(),
                type: $form.attr('method'),
                error: function(xhr) {
                    window.General.convertErrorsToList($errors, xhr.responseText);
                },
                success: function(response) {
                    if (response.status) {
                        $('.modal').modal('hide');

                        /* DC-632 Fix for not hiding modal backdrop on test */
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        /* DC-632 */

                        window.General.displayMsg($('.alertEditedSuccess'));
                        window.General.doFilter(window.General.filterParams);
                    }
                }
            });
        },

        convertDecimalValue: function(param) {
            if (/^[\d]+[,]*/.test(param)) {
                return param.replace(',', '.');
            }
            return param;
        }
    };

    function updFiltersToLatestAcceptedParams($params) {
        var $form = $('#formFilters');

        $.each($params, function(key, el) {
            $form.find('[name="' + el.name + '"]').val(el.value);
        });
    }

    function getDataWithEncodedParams(form, paramNames) {
        var data = form.serializeArray(),
            searchInput;
        if (Array.isArray(paramNames) && paramNames.length) {
            paramNames.forEach(function(item) {
                data.forEach(function(obj) {
                    if (obj.name === item) {
                        searchInput = form.find('input[name="' + item + '"]');
                        obj.value = encodeURIComponent(searchInput.val());
                    }
                });
            });

            return data;
        }
    }

    // DOM ready event
    $(function generalInit() {
        window.General.init();
        window.General.initFilters();
    });
}(window.$, window));
