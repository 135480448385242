(function($, window) {
    window.Ingredients = {
        init: function() {
            $(window.document)
                .on('click', '.btnCreateIngredient', createIngredient)
                .on('click', '.btnEditIngredient', editIngredient)
                .on('click', '.btnDeleteIngredient', deleteIngredient)
                .on('click', '.btnCancelIngredient', cancelIngredientTrans)
                .on('click', '[data-module^="ingredients"] .btnFilter', function() {
                    window.General.doFilter($('form#formFilters').serializeArray());
                })
                .on('keypress', '#formCreateIngredient input:not(input[name="name"])', allowFloat)
                .on('keypress', '#formEditIngredient input:not(input[name="name"])', allowFloat)
                .on('click', 'td.trans, a.trans', translateIngredient)
                .on('click', '#btnNewIngredient', function() {
                    $(this).parents().find('.alert-danger').addClass('hide');
                    $('form#formCreateIngredient')[0].reset();
                })
                .on('show.bs.modal', '#modalEditIngredient', function(e) {
                    var $row = $(e.relatedTarget),
                        $form = $(e.currentTarget).find('form#formEditIngredient'),
                        actionDelete;

                    // hide error messages
                    $(this).find('.alert-danger').addClass('hide');

                    $form.find('input[name="id"]').val($row.data('item-id'));
                    $form.find('[name="language_id"]').val($('#currentLangId').val());

                    $row.find('td').each(function() {
                        var $td = $(this),
                            tdname = $td.data('prop'),
                            value;

                        if (typeof tdname !== 'undefined') {
                            value = $td.data(tdname);
                            if (typeof value !== 'undefined' &&
                                (value !== null || tdname === 'carbs'
                                || tdname === 'fat' || tdname === 'protein'
                                || tdname === 'name')
                            ) {
                                $(e.currentTarget).find('input[name="' + tdname + '"]').val(value);
                            }
                        }
                    });

                    actionDelete = $('.btnDeleteIngredient').data('action') + '/' + $row.data('id');
                    $('.btnDeleteIngredient').attr('data-action', actionDelete);
                    $(this).find('#wrBtnDelete').removeClass('hidden');
                });

            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());

            if ($('#langName').text() === '-1') {
                $('#btnNewIngredient, input[name="namefilter"], .panel-body button').attr('disabled', 'disabled');
                $('body').off('click', '.clearFilters');
            }
        },
        translate: translateIngredient
    };

    function allowFloat(e) {
        var keyCode = window.event ? e.keyCode : e.which;

        if ((keyCode < 48 || keyCode > 57) && keyCode !== 44 && keyCode !== 46) {
            if (keyCode !== 0 && keyCode !== 8 && keyCode !== 13 && !e.ctrlKey) {
                e.preventDefault();
            }
        }
    }

    function deleteIngredientTrans(e) {
        var $btn = $(this),
            $modal = $btn.parents('.modal'),
            $confirmModal = $('#confirmAction'),
            $errors = $('#formEditTrans').find('.alert'),
            $row = $('#listIngredients tr[data-id="' + $btn.data('id') + '"]');

        e.preventDefault();

        $confirmModal.find('.deleteTrans').removeClass('hidden').siblings().addClass('hidden');
        $confirmModal.modal('show');
        if ($confirmModal.hasClass('hide')) {
            $confirmModal.removeClass('hide');
        }

        $('.btnActionConfirm').unbind('click').on('click', function() {
            $.ajax({
                url: $btn.data('action'),
                type: 'DELETE',
                data: {'_token': $btn.data('token')},
                success: function(response) {
                    var msg,
                        num,
                        message;

                    if (response.status) {
                        $confirmModal.modal('hide');
                        $modal.modal('hide');
                        $row.remove();
                        window.General.displayMsg($('.alertTransDeletedSuccess'));

                        msg = $('#counterRows').html();
                        num = parseInt(msg, 10);
                        message = msg.replace(num, '');

                        $('#counterRows').html(response.remainedIngredientCount + message);
                    }
                },
                error: function(xhr) {
                    $('#formEditIngredient').removeClass('changed');
                    $confirmModal.modal('hide');
                    window.General.convertErrorsToList($errors, xhr.responseText);
                }
            });
        });
    }

    function markChanged() {
        var $form = $(this).parents('form');
        if (!$form.hasClass('changed')) {
            $form.addClass('changed');
        }
    }

    function saveIngredientTrans(e) {
        var $form = $(this).parents('form'),
            $errors = $form.find('.alert-danger');

        e.preventDefault();

        $.ajax({
            url: $form.attr('action'),
            data: $form.serializeArray(),
            type: $form.attr('method'),
            error: function(xhr) {
                $form.removeClass('changed');
                window.General.convertErrorsToList($errors, xhr.responseText);
            },
            success: function(response) {
                if (response.status === true) {
                    $('.modal').modal('hide');
                    window.General.displayMsg($('.alertTransSuccess'));
                    window.General.doFilter($('form#formFilters').serializeArray());
                }
            }
        });
    }

    function cancelIngredientTrans() {
        var $btn = $(this),
            $modalConfirm = $('#confirmAction'),
            $modal = $btn.parents('.modal'),
            $form = $btn.parents('form');

        if ($('[data-module^="recipes/market"]').length > 0) { return; }
        if ($(this).parents('form').hasClass('changed')) {
            if ($modalConfirm.hasClass('hide')) {
                $modalConfirm.removeClass('hide');
            }
            $modalConfirm.find('.cancel').removeClass('hidden').siblings().addClass('hidden');
            $modalConfirm.modal('show');
        } else {
            $modal.modal('hide');
        }

        $('.btnActionConfirm').unbind('click').on('click', function() {
            $form.removeClass('changed');
            $modal.modal('hide');
            $modalConfirm.modal('hide');
            $btn.parents('form')[0].reset();
        });
    }

    function translateIngredient(e) {
        var $modal,
            $url,
            $target = $(e.target);

        if ($target.hasClass('trans')) {
            e.preventDefault();
            e.stopPropagation();

            $modal = $('#modalIngredTrans');
            if ($target.attr('href')) {
                $url = $(e.target).attr('href');
            } else {
                $url = $(e.target).find('a.trans').attr('href');
            }

            $.ajax({
                url: $url + '/' + $('#currentLangId').val(),
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-content').html(response);
                    $modal.modal('show');
                    $('.btnCancelTrans').on('click', cancelIngredientTrans);
                    $('.btnSaveTrans').on('click', saveIngredientTrans);
                    $('.btnDeleteTrans').on('click', deleteIngredientTrans);
                    $('input, select').on('change', markChanged);
                }
            });
        }
    }

    function createIngredient(e) {
        var $form = $(this).parents('form'),
            $errors = $form.prev();

        if ($('[data-module ^= "recipes"]').length === 1) {
            return;
        }

        $form.find('[name="language_id"]').val($('#currentLangId').val());
        e.preventDefault();

        $.ajax({
            url: $form.attr('action'),
            data: $form.serializeArray(),
            type: $form.attr('method'),
            error: function(xhr) {
                window.General.convertErrorsToList($errors, xhr.responseText);
            },
            success: function() {
                $('.modal').modal('hide');
                window.General.displayMsg($('.alertSavedSuccess'));
                window.General.doFilter(window.General.filterParams);
            }
        });
    }

    function editIngredient(e) {
        var $form = $('form#formEditIngredient'),
            $errors = $form.prev();

        e.preventDefault();

        $.ajax({
            url: $form.attr('action'),
            data: $form.serializeArray(),
            type: $form.attr('method'),
            error: function(xhr) {
                window.General.convertErrorsToList($errors, xhr.responseText);
            },
            success: function(response) {
                if (response.status === true) {
                    $('.modal').modal('hide');
                    window.General.displayMsg($('.alertEditedSuccess'));
                    window.General.doFilter($('form#formFilters').serializeArray());
                }
            }
        });
    }

    function deleteIngredient(e) {
        var $btn = $(this),
            $modal = $btn.parents('.modal'),
            $confirmModal = $('#confirmAction'),
            $errors = $('#formEditIngredient').prev(),
            ingredientId = $('#formEditIngredient').find('input[name=id]').val(),
            $row = $('#listIngredients tr[data-id="' + ingredientId + '"]');

        e.preventDefault();

        $confirmModal.find('.delete').removeClass('hidden').siblings().addClass('hidden');
        $confirmModal.modal('show');
        if ($confirmModal.hasClass('hide')) {
            $confirmModal.removeClass('hide');
        }

        $('.btnActionConfirm').unbind('click').on('click', function() {
            $.ajax({
                url: $btn.attr('data-action'),
                type: 'DELETE',
                data: {'_token': $btn.data('token')},
                success: function(response) {
                    var msg,
                        num,
                        message;

                    if (response.status) {
                        $confirmModal.modal('hide');
                        $modal.modal('hide');
                        $row.remove();
                        window.General.displayMsg($('.alertSuccessDelete'));

                        msg = $('#counterRows').html();
                        num = parseInt(msg, 10);
                        message = msg.replace(num, '');

                        $('#counterRows').html(response.remainedIngredientCount + message);
                    }
                },
                error: function(xhr) {
                    $('#formEditIngredient').removeClass('changed');
                    $confirmModal.modal('hide');
                    window.General.convertErrorsToList($errors, xhr.responseText);
                }
            });
        });
    }

    // DOM ready event
    $(function ingredientsInit() {
        window.Ingredients.init();
    });
}(window.$, window));
