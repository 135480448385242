(function($, window) {
    var UNAUTHORIZED = 'Unauthorized.',
        Auth = {

            /**
             * init
             *
             * @return undefined
             */
            init: function() {
                this.initAjaxConfig();
                this.initEvents();
            },

            /**
             * initAjaxConfig
             *
             * @return undefined
             */
            initAjaxConfig: function() {
                var _this = this;

                function createNewErrorHandler(error) {
                    return function(xhr, textStatus, errorThrown) {
                        if (xhr.status === 401) {
                            return _this.redirect(xhr);
                        }

                        error(xhr, textStatus, errorThrown);
                    };
                }

                $.ajaxSetup({
                    beforeSend: function(xhr, settings) {
                        var error;

                        if (typeof settings.error === 'function') {
                            error = settings.error;

                            settings.error = createNewErrorHandler(error);
                        }
                    },

                    statusCode: {
                        401: function(xhr) {
                            _this.redirect(xhr);
                        }
                    }
                });
            },

            /**
             * initEvents
             *
             * @return undefined
             */
            initEvents: function() {
                $(document).on('click', '.js-auth', function() {
                    $.get('/');
                });
            },

            /**
             * redirect
             *
             * @param {Object} xhr
             *
             * @return undefined
             */
            redirect: function(xhr) {
                if (xhr.responseText === UNAUTHORIZED) {
                    window.location.href = '/';
                }
            }
        };

    $(function authInit() {
        Auth.init();
    });
}(window.$, window));
