(function($, window) {
    window.Recipes = {
        fUpload: $('#formUploadImg'),
        modalRecipe: '#modalCreateRecipe',

        init: function() {
            $(window.document)
                .on('click', '#showCreateModal', this.showCreateModal)
                .on('click', '.btnCreateRecipe', this.create)
                .on('click', '.btnEditRecipe', window.General.saveModal)
                .on('click', '.btnCreateIngredient', createIngredient)
                .on('click', '.btnCancelIngredient', closeIngredientModal)
                .on('click', '#btnNoResult', showCreateIngredient)
                .on(
                    'change',
                    '#modalRecipe input, #modalRecipe select, #modalRecipe textarea',
                    window.General.markTrAsChanged
                )
                .on('click', '#modalRecipe .btnEditTranslation', window.General.editTranslationModal)
                .on('change', '#recipe_origin_lang', this.changeOriginLang)
                .on('click', '.btnUploadImg', $.proxy(function(e) {
                    this.initBtnUpload(true, $(e.currentTarget));
                }, this))
                .on('change', '#chooseFile', this.initPreviewImg)
                .on('click', '.btnDeleteImg', this.initDeleteImg)
                .on('click', 'tr[data-recipe-type]', this.initEditOrTranslateEvent)
                .on('show.bs.modal', '#modalRecipe', this.initIngredients)
                .on(
                    'click',
                    '[data-module^="recipes"] .btnFilter, [data-module^="recipes"]  .withoutTranslation',
                    this.filter
                )
                .on('change', '#ddMarketlist', this.filter)
                .on('click', '#btnCreateTag', this.recipeCreateTag)
                .on('shown.bs.modal', '#modalRecipe, #createModalBox', $.proxy(function(e) {
                    this.initPluginImg($(e.currentTarget));
                }, this))
                .on('shown.bs.modal', '#modalRecipe, #createModalBox', function() {
                    $('[data-toggle="tooltip"]').map(function(index, item) {
                        var $item = $(item);
                        toggleTooltip($item);
                    });
                })
                .on('hidden.bs.modal', '.modal', function() {
                    var $body = $('body');
                    if ($('.modal:visible').length && !$body.hasClass('modal-open')) {
                        $body.addClass('modal-open');
                    }
                })
                .on('click', '.popover-close', function() {
                    var id = $(this).parents('.popover').attr('id');
                    $('[aria-describedby="' + id + '"]').trigger('click');
                });

            $(window.document).on('drop dragover', function(e) {
                e.preventDefault();
            });

            window.General.initChosen($('select#ddMarketlist'));
            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());

            $('.convert-comma').autoNumeric('init', {
                aSep: ' ',
                aDec: ','
            });
        },
        create: function() {
            var $btn = $(this),
                $form = $btn.parents('form'),
                $errors = $form.prev(),
                currentLanguage = $('.listItems').find('#currLang');

            if (currentLanguage.length === 1) {
                $form.find('[name="languages_id"]').val(currentLanguage.val());
            }

            $('.btnCreateRecipe, .btnCancel').button('loading');
            $('.btnImgAction').addClass('hide');
            $errors.addClass('hide');
            window.General.updStepsTextarea($form);
            $.ajax({
                url: $form.attr('action'),
                data: $form.serializeArray(),
                type: $form.attr('method'),
                error: function(xhr) {
                    window.General.convertErrorsToList($errors, xhr.responseText);
                },
                success: function(response) {
                    if ($('#formCreateRecipe .btnUploadImg.uploadActivated').length > 0 && response.id) {
                        window.Recipes.doUploadImage(response.id);
                    } else {
                        $('.modal').modal('hide');
                        window.General.displayMsg(
                            ($('.withoutTranslation').prop('checked') === false)
                                ? $('.alertSavedSuccess')
                                : $('.alertTranslateSavedSuccess')
                        );
                        window.General.doFilter(window.General.filterParams);
                        $('.btnCreateRecipe, .btnCancel').button('reset');
                        $('.btnImgAction').removeClass('hide');
                    }
                }
            });
            event.preventDefault();
        },

        deleteModal: function() {
            var $btn = $(this),
                $counterRows = $('#counterRows');

            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $.ajax({
                    url: $btn.data('action'),
                    data: {'_token': $btn.data('token')},
                    type: 'DELETE',
                    success: function(responce) {
                        var id,
                            $tr,
                            idEditForm;

                        if (responce.status) {
                            id = $btn.attr('data-id');
                            $tr = $('tr[data-content-id="' + id + '"]');
                            idEditForm = $($tr.attr('data-recipe-target'));

                            $tr.remove(); // remove the source for the edit form
                            $(idEditForm).remove();
                            $counterRows.html(parseInt($counterRows.html(), 10) - 1);
                            $('.modal').modal('hide');
                            window.General.displayMsg($('.alertSuccessDelete'));
                        }
                    }
                });
            });

            return false;
        },

        initBtnUpload: function(triggerClick, $btnUpload) {
            if (typeof $btnUpload.attr('disabled') !== 'undefined') {
                return;
            }

            $('.alert').addClass('hide');
            $btnUpload.addClass('uploadActivated');

            if (triggerClick) {
                $('#chooseFile').click();
            }
        },

        initPreviewImg: function() {
            var input = this,
                $errors = $('.uploadActivated').parents('form').prev(),
                uploadErrors = [],
                reader;

            if ($('#modalRecipe').hasClass('in')) {
                $('.btnImgAction .btn').button('loading');
            }

            if (input.files && input.files[0]) {
                uploadErrors = window.Recipes.getImgErrors(input.files[0]);

                if (uploadErrors.length > 0) {
                    window.General.convertErrorsToList($errors, JSON.stringify(uploadErrors));
                } else {
                    reader = new FileReader();

                    reader.onload = function(e) {
                        window.Recipes.updateImg(e.target.result);
                    };

                    reader.readAsDataURL(input.files[0]);
                }
            }
        },

        initPluginImg: function($context) {
            var recipeId = $('.btnUploadImg', $context).data('id') || -1,
                $form = $('#formCreateRecipe'),
                $btnStart = $('#btnStartUpload'),
                $btnUpload = $('.btnUploadImg', $context);

            window.Recipes.fUpload.fileupload({
                dropZone: $('.recipe-img-block'),
                acceptFileTypes: /(\.|\/)(jpe?g|png)$/i,
                maxFileSize: 5000000,
                url: window.Recipes.fUpload.attr('action') + '/' + recipeId,
                maxNumberOfFiles: 1,
                singleFileUploads: false,

                dragover: function() {
                    window.Recipes.initBtnUpload(false, $btnUpload);
                },

                drop: function(e, data) {
                    window.Recipes.initPreviewImg.call(data);
                },

                add: function(e, data) {
                    if (recipeId > 0) {
                        data.submit();
                    }

                    $btnStart.unbind('click').on('click', function() {
                        data.submit();
                    });
                },

                fail: function() {

                },
                done: function(e, data) {
                    $('.btnImgAction .btn').button('reset');
                    window.Recipes.updateImg(data.result.fileName);

                    if ($form.is(':visible') && $form.find('.btnUploadImg.uploadActivated').length > 0) {
                        $('.btnCreateRecipe, .btnCancel').button('reset');
                        $('.modal').modal('hide');
                        window.General.displayMsg($('.alertSavedSuccess'));
                        $('.btnImgAction').removeClass('hide');
                        window.General.doFilter(window.General.filterParams);
                    } else {
                        showBtnDeleteImg();
                    }
                    $('button').removeClass('uploadActivated');
                }
            });
        },
        getImgErrors: function(fileName) {
            var $fCreateRecipe = $('#formCreateRecipe'),
                acceptFileTypes = /^image\/(gif|jpe?g|png)$/i,
                uploadErrors = [],
                $form;

            if (fileName.type.length && !acceptFileTypes.test(fileName.type)) {
                uploadErrors.push('Invalid file type, aborted');
            }
            if (fileName.size && fileName.size > 5000000) {
                uploadErrors.push('Maximum size of image should be 5 Mb.');
            }

            if (uploadErrors.length > 0) {
                showBtnUploadImg();
                $('.btnImgAction .btn').button('reset');
                $fCreateRecipe.find('.uploadActivated').removeClass('uploadActivated');

                // clear image
                $form = ($fCreateRecipe.is(':visible')) ? $fCreateRecipe : $('#modalRecipe form');
                $form.find('.imgRecipe').attr('src', '');
            }

            return uploadErrors;
        },

        updateImg: function(imgSrc) {
            var $container = $('button.uploadActivated').parents('form');

            $container.find('.recipe-img-block .imgRecipe', '').attr('src', imgSrc);

            if ($container.attr('id') !== 'formCreateRecipe') {
                showBtnDeleteImg();
                window.General.displayMsg($container.find('.label-msg-upload'));
            } else {
                $('#formCreateRecipe .uploadActivated').addClass('hide').siblings().removeClass('hide');
            }
        },

        initDeleteImg: function() {
            var $btn = $(this),
                $form = $('#formDeleteImg'),
                $formCreate = $('#formCreateRecipe'),
                emptyImg = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';

            if ($formCreate.find('.btnUploadImg.uploadActivated').length === 1) {
                $formCreate.find('.imgRecipe').attr('src', emptyImg);
                $formCreate.find('.btnUploadImg').removeClass('uploadActivated').removeClass('hide');
                $btn.removeClass('hide');

                return;
            }

            $.ajax({
                url: $form.attr('action') + '/' + $btn.data('id'),
                data: $form.serializeArray(),
                type: 'DELETE',
                success: function(response) {
                    if (response.status) {
                        $btn.parents('form').find('img').attr('src', emptyImg);
                        showBtnUploadImg();
                        window.General.displayMsg($('#modalRecipe').find('.label-msg-delete'));
                    }
                }
            });
        },

        doUploadImage: function(recipeId) {
            window.Recipes.fUpload.fileupload('option', 'url', window.Recipes.fUpload.attr('action') + '/' + recipeId);
            $('#btnStartUpload').click();
        },

        showCreateModal: function() {
            var $btn = $(this),
                currentLanguage = $('#currLang').val();

            window.General.clearCreateFields();
            window.Recipes.modalRecipe = '#modalCreateRecipe';
            $btn.button('loading');

            $.ajax({
                url: '/recipes/get-create-modal/' + currentLanguage,
                type: 'GET',
                success: function(response) {
                    var $modal = $('#createModalBox');
                    $modal.html(response);
                    $modal.find('select option').removeAttr('selected');
                    window.General.initChosen($modal.find('select'));
                    initChosenNoResults($modal.find('select.cbTags'));

                    $('#createModalBox .modal').on('show.bs.modal', window.Recipes.initIngredients);
                    $('#createModalBox .modal').modal('show');
                    $btn.button('reset');
                }
            });
        },
        initEditOrTranslateEvent: function() {
            var $modal,
                $tr,
                currentLanguage,
                $listRecipes = $('#listRecipes');

            if ($listRecipes.hasClass('disabled')) {
                return false;
            }

            $modal = $('#modalRecipe');
            $tr = $(this);
            currentLanguage = parseInt($('.listItems').find('#currLang').val(), 10);
            window.Recipes.modalRecipe = '#modalRecipe';

            $listRecipes.addClass('disabled');
            if ($tr.data('language') === currentLanguage && $tr.data('origin-recipe') === 1) {
                $.ajax({
                    url: '/recipes/edit/' + $tr.data('content-id'),
                    type: 'GET',
                    success: function(response) {
                        $modal.find('.modal-body').html(response);
                        $modal.find('.cbTags').chosen({
                            inherit_select_classes: true,
                            display_selected_options: false
                        });
                        initChosenNoResults($modal.find('select.cbTags'));

                        if ($modal.find('img').attr('src') === '') {
                            $modal.find('.btnUploadImg').removeClass('hide');
                        } else {
                            $modal.find('.btnDeleteImg ').removeClass('hide').siblings().addClass('hide');
                        }

                        $modal.find('#languages_id').val(currentLanguage);
                        $('#titleEdit').show().siblings().hide();

                        $('#listRecipes').removeClass('disabled');
                        window.General.initChosen($('#modalRecipe select'));
                        $modal.modal('show');
                    }
                });
            } else {
                $.ajax({
                    url: '/recipes/translate/get-modal/' + $tr.data('id') + '/' + currentLanguage,
                    type: 'GET',
                    success: function(response) {
                        $modal.find('.modal-body').html(response);
                        $modal.find('#languages_id').val(currentLanguage);
                        $('#sbTranslateLang').html($('#sourceForSelectBoxTranslateLang').html());
                        $('#titleTranslate').show().siblings().hide();

                        $('#listRecipes').removeClass('disabled');
                        window.General.initChosen($('#modalRecipe select'));
                        initStepsPopover();
                        $modal.modal('show');

                        window.General.updStepsTextarea($modal);
                    }
                });
            }
        },
        recipeCreateTag: function(e) {
            var $sb = $('select.cbTags'),
                $modal = $('#modalRecipe'),
                currentLanguage = parseInt($('.listItems').find('#currLang').val(), 10),
                tagName = $('.tag_name').html();

            e.preventDefault();

            $.ajax({
                url: '/recipes/create-tag',
                data: {
                    _token: $(this).data('token'),
                    recipe_id: $modal.find('[name="id"]').val(),
                    name: tagName,
                    languages_id: currentLanguage
                },
                type: 'post',
                error: function(xhr) {
                    var $msgBox = $('.no-results .alert'),
                        err = JSON.parse(xhr.responseText).name;

                    $msgBox.html(err);
                    window.General.displayMsg($msgBox);
                },
                success: function(response) {
                    if (response.status === true) {
                        $sb.append('<option selected value="' + response.tagId + '">' + tagName + '</option>');
                        $sb.trigger('chosen:updated');
                    }
                }
            });
        },
        changeOriginLang: function() {
            var $form = $(this).parents('.modal').find('form'),
                originLang = $('#recipe_origin_lang option:selected').val();

            $.ajax({
                url: '/recipes/translate/get-origin-recipe/'
                    + $form.find('[name="recipe_id"]').val()
                    + '/'
                    + originLang,
                type: 'GET',
                success: function(response) {
                    var recipeContent = response.recipe_content[0],
                        steps = recipeContent.steps;

                    $('.originName').html(recipeContent.name);
                    $('#origDescrContent').html('<div id="origDescr">' + recipeContent.description + '</div>');
                    $('#origStepsContent').html(steps.replace('contenteditable="true"', ''));

                    initStepsPopover();
                }
            });
        },
        filter: function() {
            $('input[name="marketId"]').val($('#ddMarketlist').val());
            window.General.filterParams = $('form#formFilters').serializeArray();
            window.General.doFilter(window.General.filterParams);
        },
        initIngredients: function() {
            var currLang = $('#currLang').val(),
                noResultMsg = $('#notFoundIngredients').html();

            $('.sortable').sortable({
                handle: '.drag'
            });

            $('.sortable li').find('input.amount').each(function() {
                var inputAmount = $(this);

                inputAmount.autoNumeric('init', {
                    aSep: ' ',
                    aDec: ',',
                    vMax: '999999.99'
                });

                // ctrl + v hook
                inputAmount.on('paste', function(e) {
                    var text,
                        newNode,
                        clp = (e.originalEvent || e).clipboardData;

                    e.preventDefault();

                    if (clp === undefined || clp === null) {
                        text = window.clipboardData.getData('text') || '';
                        if (text !== '') {
                            if (window.getSelection) {
                                newNode = document.createElement('span');
                                newNode.innerHTML = text;
                                window.getSelection().getRangeAt(0).insertNode(newNode);
                            } else {
                                document.selection.createRange().pasteHTML(text);
                            }
                        }
                    } else {
                        text = clp.getData('text/plain') || '';
                        if (text !== '') {
                            document.execCommand('insertText', false, text);
                        }
                    }

                    inputAmount.val(text.replace('.', ','));
                });
            });

            $('input.amount').on('keyup', recountCalories);
            $('.ingredientname').on('keyup', clearNoResult);
            $('.btnRemove').on('click', removeIngredientFromList);
            $('.ddMeasures select').on('change', recountCalories);
            $('.amount').on('keypress', allowNumbers);

            $('.ingredientname').autocomplete({
                source: function(request, response) {
                    // get list of ingredients which have been already added
                    // in order to exclude them from autocomplete
                    var ingredList = [],
                        $ul,
                        $lis;


                    if ($('#modalRecipe').is(':visible')) {
                        $ul = $('#modalRecipe').find('ul.sortable');
                    } else {
                        $ul = $('#modalCreateRecipe').find('ul.sortable');
                    }

                    $lis = $ul.find('li');
                    $lis.each(function() {
                        var id = $(this).find('input[name=ingredient_id]').val();
                        ingredList.push(id);
                    });

                    $.ajax({
                        dataType: 'json',
                        type: 'get',
                        data: {exludeIngredIds: ingredList, term: request.term, langId: currLang},
                        url: '/recipes/autocomplete',
                        success: function(data) {
                            response(data);
                        }
                    });
                },
                minLength: 0,
                delay: 300,
                messages: {
                    noResults: noResultMsg,
                    results: function() {
                    }
                },
                select: function(event, ui) {
                    $('.ingredientname').autocomplete('disable');
                    addIngredientItem(ui.item.id);
                },
                response: function(event, ui) {
                    if (ui.content.length === 0 && $('input.ingredientname').val() !== '') {
                        $('.empty-message').html(noResultMsg);
                    } else {
                        $('.empty-message').empty();
                    }
                }
            });

            $('.convert-comma').autoNumeric('init', {
                aSep: ' ',
                aDec: ','
            });
        }
    };

    function showTotalCal() {
        var calTotal = 0,
            $form = $('#modalRecipe');

        if ($('#modalCreateRecipe').is(':visible')) {
            $form = $('#modalCreateRecipe');
        }

        $form.find('ul.sortable li .calNum .cal span').each(function() {
            var calSpan = $(this);
            calTotal += parseFloat(calSpan.autoNumeric('get'));
        });

        $form.find('#calTotal').autoNumeric('set', calTotal);
    }

    // show or hide tooltip next to the item
    function showTooltip($item, originalValue, length) {
        if ($item.text().indexOf('.') > 3
            || ($item.text().length > length && $item.text().indexOf('.') === -1)
            || window.General.isEllipsisActive($item)
        ) {
            $item.attr('data-original-title', originalValue);
            $item.attr('data-toggle', 'tooltip');
            $item.attr('data-placement', 'top');
            $item.tooltip();
        } else {
            $item.removeAttr('data-toggle').removeAttr('title').tooltip('destroy');
        }
    }

    function removeIngredientFromList(e) {
        $(e.target).parents('li').remove();
        showTotalCal();
    }

    function clearNoResult(e) {
        var $input = $(e.target);
        if ($input.val() === '') {
            $input.next().empty();
        }
    }

    function recountCalories(e) {
        var measure = 0, // selected measure (g,hg,kg, etc.)
            newAmount, // new amount of calories
            $caloriesAmount,
            cal,
            calculatedVal,
            $amount;

        if ($(e.target).is('input')) {
            $amount = $(this);
            measure = $(e.target).parents('div').siblings('.ddMeasures').find('select').val();
        } else if ($(e.target).is('select')) {
            $amount = $(this).parents().siblings('.amount').find('input');
            measure = $(this).val();
        }

        $caloriesAmount = $(e.target).parents('div').siblings('div.calNum').find('.cal span');

        // get cal of the ingredient per selected measure
        cal = $(e.target).parents('div').siblings('.measures').find('input[name=measure_type_' + measure + ']').val();

        newAmount = $amount.autoNumeric('get');
        calculatedVal = newAmount * cal;

        $caloriesAmount.autoNumeric('set', calculatedVal);
        showTooltip($caloriesAmount, calculatedVal, 5);
        showTotalCal();
    }

    function allowNumbers(e) {
        var keyCode = window.event ? e.keyCode : e.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 190 && keyCode !== 110) {
            if (keyCode !== 0 && keyCode !== 8 && keyCode !== 13 && keyCode !== 44 && keyCode !== 46
                && !e.ctrlKey) {
                e.preventDefault();
            }
        }
    }

    function showBtnDeleteImg() {
        $('.uploadActivated').addClass('hide').parents('form').find('.btnDeleteImg').removeClass('hide');
    }

    function showBtnUploadImg() {
        $('.btnDeleteImg').addClass('hide').parents('form').find('.btnUploadImg ').removeClass('hide');
    }

    function initStepsPopover() {
        $('#originDescription[aria-describedby], #originSteps[aria-describedby]').trigger('click');

        $('#originSteps').popover({
            html: true,
            placement: 'top',
            viewport: {
                selector: 'body',
                padding: 30
            },
            title: function() {
                return $('#origStepsTitle').html();
            },
            template: '<div class="popover popover-lg" role="tooltip">'
                + '<div class="arrow"></div>'
                + '<h3 class="popover-title"></h3>'
                + '<div class="popover-content"></div></div>',
            content: function() {
                return $('#origStepsContent').html();
            }
        });

        $('#originDescription').popover({
            html: true,
            placement: 'top',
            viewport: {
                selector: 'body',
                padding: 30
            },
            title: function() {
                return $('#origDescrTitle').html();
            },
            content: function() {
                return $('#origDescrContent').html();
            }
        });
    }

    function initChosenNoResults($sb) {
        $sb.on('chosen:no_results', function(evt, params) {
            $('.tag_name').html(params.chosen.search_field.val());
            $('.no-results').html($('#no-results-content').html());
        });
    }

    function addIngredientItem(id) {
        var $modal = $('.modal'),
            $errors = $modal.find('.alert'),
            $ingredient = $('.ingredientname');

        $.ajax({
            url: '/recipes/get-ingredient/' + id,
            type: 'GET',
            error: function(xhr) {
                $('.ingredientname').val('');
                window.General.convertErrorsToList($errors, xhr.responseText);
            },
            success: function(response, status) {
                var $ul = $modal.find('.sortable'),
                    $ingredientName,
                    name;

                if (status === 'success') {
                    $('<li>').html(response).appendTo($ul);
                    $ingredient.parents('form').find('.btnCancelModal').addClass('flDataChanged');

                    name = $(response).find('input.amount').attr('name');
                    $('[name="' + name + '"]').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ',',
                        vMax: '9999.99'
                    });

                    $('.convert-comma').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });

                    window.General.initChosen($('.ddMeasures select'));
                    $('.btnRemove').on('click', removeIngredientFromList);
                    $('input.amount').on('keyup', recountCalories);
                    $('input.amount').on('keypress', allowNumbers);
                    $('.ddMeasures select').on('change', recountCalories);
                    $modal.find('.ingredientname').val('');
                    $modal.find('.btnCancel').addClass('flDataChanged');
                    $('.sortable select[name=measures]').change();

                    $modal.find('.calNum .cal span').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });

                    showTotalCal();

                    $ingredientName = $ul.find('li').first().find('.name');
                    toggleTooltip($ingredientName);

                    $('.ingredientname').autocomplete('enable');
                }
            }
        });
    }

    function toggleTooltip($e) {
        if (window.General.isEllipsisActive($e)) {
            $e.tooltip();
        } else {
            $e.removeAttr('data-toggle').removeAttr('title').tooltip('destroy');
        }
    }

    function showCreateIngredient() {
        var $mIngred = $('#modalIngredient');

        $mIngred.modal('show');

        $(window.Recipes.modalRecipe).modal('hide');

        $mIngred.find('input[type="text"]').val('');
        $mIngred.find('.alert-danger').addClass('hide');
        $mIngred.find('[name="name"]').val($('.ingredientname').val());
    }

    function closeIngredientModal() {
        var $btn = $(this);

        if ($('[data-module^="ingredients/market"]').length > 0) { return; }
        if ($('#formCreateIngredient').hasClass('changed')) {
            window.General.showConfirmModal($btn);
            $('.btnConfirmAction').unbind('click').on('click', function() {
                $('#formCreateIngredient').removeClass('changed');
                $('#confirmModal').modal('hide');
                $('#modalIngredient').modal('hide');
                $(window.Recipes.modalRecipe).modal('show');
            });
        } else {
            $(window.Recipes.modalRecipe).modal('show');
        }
    }

    function createIngredient() {
        var $mIngred = $('#modalIngredient'),
          $form = $mIngred.find('form'),
          $errors = $form.prev(),
          currentLang = $('.listItems').find('#currLang').val();

        if ($('[data-module ^= "recipes"]').length !== 1) {
            return;
        }

        $form.find('[name="language_id"]').val(currentLang);

        $.ajax({
            url: $form.attr('action'),
            data: $form.serializeArray(),
            type: $form.attr('method'),
            error: function(xhr) {
                window.General.convertErrorsToList($errors, xhr.responseText);
            },
            success: function(response) {
                var $ingredNameRecipe = $('.ingredientname'),
                  $emptyMsg = $('.empty-message');

                $mIngred.modal('hide');
                $(window.Recipes.modalRecipe).modal('show');
                addIngredientItem(response.id);

                $mIngred.find('input[type="text"]').val('');
                $mIngred.find('.alert-danger').addClass('hide');
                $emptyMsg.html('');
                $ingredNameRecipe.val('');
            }
        });
    }

    // DOM ready event
    $(function recipesInit() {
        window.Recipes.init();
    });
}(window.$, window));
