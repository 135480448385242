(function($, window) {
    window.ExtraIntakes = {
        init: function() {
            var self = this;

            $(window.document)
                .on('change', '[data-module^="extra-intakes"] .withoutTranslation', self.filter)
                .on('click', '#btnShowModal', self.showCreateModal)
                .on('click', '.btnCreateExtraIntake', self.btnCreateExtraIntake)
                .on('click', '.btnEditExtraIntake', window.General.saveModal)
                .on('click', '[data-module^="extra-intakes"] .btnTranslateModal', self.showTranslateModal)
                .on('click', '[data-module^="extra-intakes"] .btnFilter', function() {
                    window.General.doFilter($('form#formFilters').serializeArray());
                })
                .on('click', '#listExtraIntakes tbody tr', self.getEditModal)
                .on('click', 'a.verify, a.verify i', self.verifyExtraIntake);

            $('[data-toggle="tooltip"]').map(function(index, item) {
                $(item).tooltip();
            });
        },

        filter: function(additionalFilters) {
            $('input[name="marketId"]').val($('#ddMarketlist').val());
            window.General.filterParams = $('form#formFilters').serializeArray();

            if ($.isArray(additionalFilters)) {
                additionalFilters.forEach(function(filterItem) {
                    window.General.filterParams.push(filterItem);
                });
            }

            window.General.doFilter(window.General.filterParams);
        },

        showTranslateModal: function() {
            var $modal = $('#editExtraIntakeModal'),
                currentLanguage = $('#currLang').val();

            $.ajax({
                url: '/extra-intakes/translate/get-modal/' + $(this).data('id') + '/' + currentLanguage,
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.find('#languages_id').val(currentLanguage);
                    $('#sbTranslateLang').html($('#sourceForSelectBoxTranslateLang').html());
                    $('#extraIntakeToLang').text($modal.find('[name="language_id"]').data('lang'));
                    $('#titleTranslate').show().siblings().hide();
                    $('#listExtraIntakes').removeClass('disabled');
                    window.General.initChosen($('#modalExtraIntake select'));
                    $modal.modal('show');
                }
            });
        },

        verifyExtraIntake: function(e) {
            var $target = $(e.target),
                $url,
                $errors;

            if (!$target.attr('href')) {
                $target = $target.parents('td').find('a.verify');
            }

            $url = $target.attr('href');

            e.preventDefault();
            e.stopPropagation();

            $.ajax({
                url: $url,
                type: 'post',
                data: {
                    _token: $target.data('token')
                },
                error: function(xhr) {
                    window.General.convertErrorsToList($errors, xhr.responseText);
                },
                success: function(response) {
                    $target.parents('tr').html(response);
                    window.General.displayMsg($('.alertVerifiedSuccess'));
                }
            });
        },

        showCreateModal: function() {
            window.General.clearCreateFields();
            $('#createExtraIntakeModal').addClass('fade').modal('show');
        },

        btnCreateExtraIntake: function() {
            var $btnCreate = $(this),
                $modal = $('#createExtraIntakeModal');

            $modal.removeClass('fade');
            window.General.save($btnCreate, window.General.closeModal.bind(window.General, $modal));
        },

        getEditModal: function() {
            var $listExtraIntakes = $('#listExtraIntakes'),
                $modal = $('#editExtraIntakeModal');

            if ($listExtraIntakes.hasClass('disabled')) {
                return false;
            }

            $listExtraIntakes.addClass('disabled');

            $.ajax({
                url: '/extra-intakes/edit/' + $(this).data('id') + '/' + $('#currLang').val(),
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.modal({keyboard: false, show: true});
                    $listExtraIntakes.removeClass('disabled');
                    window.General.displayMsg($('.alertUpdatedSuccess'));
                    $('#titleEdit').show().siblings().hide();
                }
            });
        }
    };

    // DOM ready event
    $(function extraIntakesInit() {
        window.ExtraIntakes.init();
    });
}(window.$, window));
