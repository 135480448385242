(function($, window) {
    window.Exercises = {
        init: function() {
            var self = this,
                floatingMenu = $('.floating'),
                topBlockPosition,
                topPosition;

            $(window.document)
                .on('change', '[data-module^="exercises"] .withoutTranslation', self.filter)
                .on('click', '[data-module^="exercises"] .btnFilter', self.filter)
                .on('click', '#btnShowModal', self.showCreateModal)
                .on('click', '.btnCreateExercise', self.createExercise)
                .on('click', '.btnCreateSubCategory', self.createSubCategory)
                .on('click', '#btnEditExerciseCategoryModal', self.editExerciseCategoryModal)
                .on('click', '#btnNewCategoryModal', self.newCategoryModal)
                .on('click', '#btnNewSubCategoryModal', self.newSubCategoryModal)
                .on('click', '.btnTranslateCategoryModal', self.translateExerciseCategory)
                .on('click', '#listExercises tbody tr', self.showEditModal)
                .on('click', '.btnEditExercise', window.General.saveModal)
                .on('click', '.btnEditExerciseCategory', window.General.saveModal)
                .on('click', '[data-module^="exercises"] .btnTranslateModal', self.showTranslateModal)
                .on('change', '#exercise_origin_lang', self.changeOriginLang)
                .on('change', '#exercise_category_lang', self.changeCategoryLang)
                .on('click', '.btnSaveTrans', self.saveTranslation)
                .on('click', '#modalExercise .btnEditTranslation', window.General.editTranslationModal);

            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());

            if ($('#listExercises').length > 0) {
                window.General.doFilter(window.General.filterParams)
                    .then(function() {
                        $('.convert-comma').autoNumeric('init', {
                            aSep: ' ',
                            aDec: ','
                        });
                    });

                topBlockPosition = floatingMenu.offset().top;
                $(window).scroll(function() {
                    topPosition = $(document).scrollTop();
                    if (topPosition > topBlockPosition) {
                        floatingMenu.addClass('fixed');
                    } else {
                        floatingMenu.removeClass('fixed');
                    }
                });
                window.Exercises.getTreeData($('input[name="categoryId"]').val());
            }
        },

        getTreeData: function(selectedId, newCategoryOrSubCategory) {
            var url = '/exercise-category/tree/' + window.localStorage.marketId,
                tree = $('#treeExerciseCategories'),
                input = $('input[name="categoryId"]'),
                selectedNodes,
                treeNode,
                cnt,
                i;

            if (selectedId) {
                url += '/' + selectedId;
            }

            $.ajax({
                url: url,
                type: 'GET',
                success: function(response) {
                    input.val(response.data.current.id);
                    $('#selectedCategoryName').text(response.data.current.name);

                    tree.treeview({
                        data: response.data.tree,
                        showTags: true,
                        levels: 5,
                        onNodeSelected: function(event, nodeItem) {
                            $('#selectedCategoryName').text(nodeItem.text);
                            $('input[name="categoryId"]').val(nodeItem.id);
                            // $('#selectedCategoryName').text(nodeItem.text);
                            window.Exercises.filter();

                            selectedNodes = $(this).treeview('getSelected');
                            cnt = selectedNodes.length;
                            for (i = 0; i < cnt; i++) {
                                if (nodeItem.nodeId !== selectedNodes[i].nodeId) {
                                    $(this).treeview('unselectNode', [ selectedNodes[i].nodeId, { silent: true } ]);
                                }
                            }
                        },
                        onNodeUnselected: function(event, nodeItem) {
                            $(this).treeview('selectNode', [nodeItem.nodeId, { silent: true }]);
                        }
                    });

                    // when creating a new category or subcategory is automatically selected
                    if (newCategoryOrSubCategory) {
                        treeNode = tree.treeview('search', [ newCategoryOrSubCategory, {
                            ignoreCase: true,     // case insensitive
                            exactMatch: false,    // like or equals
                            revealResults: true   // reveal matching nodes
                        }]);
                        tree.treeview('clearSearch');

                        if (treeNode) {
                            input.val(treeNode[0].id);
                            tree.treeview('selectNode', [treeNode, {silent: true}]);
                        }
                    }
                }
            });
        },

        editExerciseCategoryModal: function() {
            var $modal = $('#modalExerciseCategory');

            $.ajax({
                url: '/exercise-category/edit/' + $('input[name="categoryId"]').val() + '/language/'
                    + $('#currLang').val(),
                type: 'GET',
                error: function() {
                    window.Exercises.translateExerciseCategory();
                },
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.modal({keyboard: false, show: true});
                    $('#titleCategoryEdit').show().siblings().hide();
                }
            });
        },

        newCategoryModal: function() {
            window.General.clearCreateFields();
            $('#parentCategoryId').val(0);
            $('.newCategoryTitle').removeClass('hide');
            $('.newSubCategoryTitle').addClass('hide');
            $('#createSubCategory').addClass('fade').modal('show');
        },

        newSubCategoryModal: function() {
            window.General.clearCreateFields();
            $('#parentCategoryId').val($('input[name="categoryId"]').val());
            $('.newSubCategoryTitle').removeClass('hide');
            $('.newCategoryTitle').addClass('hide');
            $('#createSubCategory').addClass('fade').modal('show');
        },

        createSubCategory: function() {
            var $btnCreate = $(this),
                $modal = $('#createSubCategory'),
                newCategoryOrSubCategory;

            $modal.removeClass('fade');

            newCategoryOrSubCategory = $('#formCreateSubCategory').find('input[name="name"]').val();
            window.General.save($btnCreate, function() {
                window.Exercises.getTreeData($('input[name="categoryId"]').val(), newCategoryOrSubCategory);
                window.General.closeModal($modal);
            });
        },

        translateExerciseCategory: function() {
            var $modal = $('#modalExerciseCategory'),
                currentLanguage = $('#currLang').val();

            $.ajax({
                url: '/exercise-category/translate/get-modal/' + $('input[name="categoryId"]').val()
                    + '/' + currentLanguage,
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.find('#languages_id').val(currentLanguage);
                    $('#sbTranslateLangCategory').html($('#sourceForSelectBoxTranslateLangCategory').html());
                    $('#exercise_category_lang option[value=' + currentLanguage + ']').attr('selected', 'selected');
                    $('#exerciseCategoryToLang').text($modal.find('[name="language_id"]').data('lang'));
                    $('#titleCategoryTranslate').show().siblings().hide();

                    window.General.initChosen($('#modalExerciseCategory select'));
                    $modal.modal('show');
                }
            });
        },

        filter: function(additionalFilters) {
            $('input[name="marketId"]').val($('#ddMarketlist').val());
            window.General.filterParams = $('form#formFilters').serializeArray();

            if ($.isArray(additionalFilters)) {
                additionalFilters.forEach(function(filterItem) {
                    window.General.filterParams.push(filterItem);
                });
            }
            window.General.doFilter(window.General.filterParams)
              .then(function() {
                  $('.convert-comma').autoNumeric('init', {
                      aSep: ' ',
                      aDec: ','
                  });
              });
        },

        showCreateModal: function() {
            window.General.clearCreateFields();
            $('#exerciseCategoryId').val($('input[name="categoryId"]').val());
            $('#createExercise').addClass('fade').modal('show');
            $('.convert-comma').autoNumeric('init', {
                aSep: ' ',
                aDec: ','
            });
        },

        createExercise: function() {
            var $btnCreate = $(this),
                $modal = $('#createExercise');

            $modal.removeClass('fade');
            window.General.save($btnCreate, window.General.closeModal.bind(window.General, $modal));
        },

        showEditModal: function() {
            var $listExercise = $('#listExercises'),
                $modal = $('#modalExercise');

            if ($listExercise.hasClass('disabled')) {
                return false;
            }

            $listExercise.addClass('disabled');

            $.ajax({
                url: '/exercises/edit/' + $(this).data('content-id') + '/language/' + $('#currLang').val(),
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.modal({keyboard: false, show: true});
                    $listExercise.removeClass('disabled');
                    $('#titleEdit').show().siblings().hide();
                    $('.convert-comma').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });
                }
            });
        },

        showTranslateModal: function() {
            var $modal = $('#modalExercise'),
                currentLanguage = $('#currLang').val();

            $.ajax({
                url: '/exercises/translate/get-modal/' + $(this).data('id') + '/' + currentLanguage,
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.find('#languages_id').val(currentLanguage);
                    $('#sbTranslateLang').html($('#sourceForSelectBoxTranslateLang').html());
                    $('#exerciseToLang').text($modal.find('[name="language_id"]').data('lang'));
                    $('#titleTranslate').show().siblings().hide();

                    $('#listExercises').removeClass('disabled');
                    window.General.initChosen($('#modalExercise select'));
                    $('.convert-comma').autoNumeric('init', {
                        aSep: ' ',
                        aDec: ','
                    });
                    $modal.modal('show');
                }
            });
        },
        changeOriginLang: function() {
            var $form = $(this).parents('.modal').find('form'),
                originLang = $('#exercise_origin_lang option:selected').val();

            $.ajax({
                url: '/exercises/translate/get-translate/'
                    + $form.find('[name="exercise_id"]').val()
                    + '/'
                    + originLang,
                type: 'GET',
                success: function(response) {
                    var exerciseContent = response.exercise_content[0];
                    $('.originName').html(exerciseContent.name);
                }
            });
        },

        changeCategoryLang: function() {
            var $form = $(this).parents('.modal').find('form'),
                originLang = $('#exercise_category_lang option:selected').val();

            $.ajax({
                url: '/exercise-category/translate/get-translate/'
                + $form.find('[name="exercise_category_id"]').val()
                + '/'
                + originLang,
                type: 'GET',
                success: function(response) {
                    $('.originName').html(response.translation.name);
                }
            });
        },

        saveTranslation: function(event) {
            var $btn = $(this),
                $form = $btn.parents('form'),
                $errors = $form.prev(),
                currentLanguage = $('.listItems').find('#currLang');

            if (currentLanguage.length === 1) {
                $form.find('[name="language_id"]').val(currentLanguage.val());
            }

            $('.btnSaveTrans, .btnCancelModal').button('loading');
            $errors.addClass('hide');

            $.ajax({
                url: $form.attr('action'),
                data: $form.serializeArray(),
                type: $form.attr('method'),
                error: function(xhr) {
                    window.General.convertErrorsToList($errors, xhr.responseText);
                },
                success: function() {
                    $btn.parents('.modal').modal('hide');

                    /* DC-632 Fix for not hiding modal backdrop on test */
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    /* DC-632 */

                    window.General.displayMsg($('.alertTranslationSavedSuccess'));
                    window.General.doFilter(window.General.filterParams);
                    $('.btnSaveTrans, .btnCancelModal').button('reset');
                }
            });
            event.preventDefault();
        }
    };

    // DOM ready event
    $(function exercisesInit() {
        window.Exercises.init();
    });
}(window.$, window));
