(function($, window) {
    window.Tags = {
        init: function() {
            $(window.document).on('click', '#btnShowModal', this.showCreateTagModal)
                .on('click', '.btnCreateTag', this.createTag)
                .on('click', '#listTags tbody tr', this.getEditModal)
                .on('click', '.btnEditTag', window.General.saveModal)
                .on('change', '[data-module^="recipe_tags"] .withoutTranslation', this.filter)
                .on('click', '[data-module^="recipe_tags"] .btnFilter', this.filter)
                .on(
                    'change',
                    '#modalRecipe input, #modalRecipe select, #modalRecipe textarea',
                    window.General.markTrAsChanged
                )
                .on('click', '#modalRecipe .btnCancel', window.General.cancelModal);

            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());
        },
        createTag: function() {
            var $btnCreate = $(this),
                $modal = $('#createTagModal');

            $modal.removeClass('fade');
            window.General.save($btnCreate, window.General.closeModal.bind(window.General, $modal));
        },
        filter: function() {
            $('input[name="marketId"]').val($('#ddMarketlist').val());
            window.General.filterParams = $('form#formFilters').serializeArray();
            window.General.doFilter(window.General.filterParams);
        },
        showCreateTagModal: function() {
            window.General.clearCreateFields();
            $('#createTagModal').addClass('fade').modal('show');
        },
        getEditModal: function() {
            var $listTags = $('#listTags'),
                $modal = $('#modalRecipe');

            if ($listTags.hasClass('disabled')) {
                return false;
            }

            $listTags.addClass('disabled');

            $.ajax({
                url: '/recipe_tags/edit/' + $(this).data('id') + '/language/' + $('#currLang').val(),
                type: 'GET',
                success: function(response) {
                    $modal.find('.modal-body').html(response);
                    $modal.modal({keyboard: false, show: true});
                    $('#listTags').removeClass('disabled');
                }
            });
        }
    };

    // DOM ready event
    $(function tagsInit() {
        window.Tags.init();
    });
}(window.$, window));
