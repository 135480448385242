(function($, window) {
    window.Feedbacks = {
        init: function() {
            $(window.document)
                .on('click', '[data-module="feedbacks"] .btnFilter', this.filter)
                .on('change', '[data-module^="feedbacks"] .withTrashed', this.filter)
                .on('keypress', 'input[name="namefilter"]', function(e) {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        $('.btnFilter').click();
                        return false;
                    }
                }).on('focusin', 'tr, button, select', function(e) {
                e.stopImmediatePropagation();
            });

            window.General.initInfiniteScroll(window.General.getInfiniteScrollSelector());

            $('select.cbFilter[name="direction"]').val('DESC');
            $('select.cbFilter[name="direction"]').trigger('chosen:updated');
        },
        filter: function() {
            window.General.filterParams = $('form#formFilters').serializeArray();
            window.General.doFilter(window.General.filterParams);
        }
    };

    // DOM ready event
    $(function feedbacksInit() {
        window.Feedbacks.init();
    });
}(window.$, window));
